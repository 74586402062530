import React, { useState } from 'react';
import Layout from '../layouts';
import Modal from 'src/components/Modal';
import TemplateBuilder from 'src/components/TemplateBuilder';

const TemplateBuilderModal = ({ reference }) => {
  const [templateBuilderModalIsOpen, setTemplateBuilderModalIsOpen] = useState(
    false
  );
  return (
    <>
      <button
        onClick={() => setTemplateBuilderModalIsOpen(true)}
        className="btn btn-link p-0"
      >
        {reference}
      </button>
      <Modal
        isOpen={templateBuilderModalIsOpen}
        title="Template Builder"
        styleType="full"
        disableForceFocus={true}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => {
          setTemplateBuilderModalIsOpen(false);
        }}
      >
        <TemplateBuilder />
      </Modal>
    </>
  );
};
const Debug = () => {
  return (
    <Layout>
      <TemplateBuilderModal reference="HA-5L9GX9" />
    </Layout>
  );
};

export default Debug;
